@import '../../common/colors.scss';

#footer {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;
	justify-content: center;
	color: $blue;
}

.blurb {
	display: flex;
	flex-direction: column;
	gap: 5px;
	text-align: center;
	font-size: 12px;

	#line2 {
		margin: 0 0 15px;
	}

	#line1 {
		margin: 0;
	}
}

#icons {
	display: flex;
	gap: 5px;
	margin-bottom: 0;

	a {
		color: $blue;
	}
}

#bar {
	height: 5px;
	background-image: radial-gradient($blue 5%, $yellow 15%, $blue 65%);
}
