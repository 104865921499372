@import '../../common/colors.scss';

#header-section {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  justify-content: space-between;
  text-align: center;
  padding: 5px 0;
	position: sticky;
	top: 0;
	width: 100%;
	background-color: white;
}

#header-links {
  display: flex;
  justify-content: center;

}

.links {
  display: inline;
  padding: 0 5px;
  font-size: 20px;
  margin-top: 25px;
}

a {
  color: $blue;
  text-decoration: none;

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
    color: darken($color: $blue, $amount: 10%)
  }
}

#header-button {
	background-color: $blue;
  border: 0px solid $black;
  border-radius: 15px;
  text-align: center;
  box-shadow: 2px 2px black;
  align-self: center;
  padding: 5px 0 5px 0;
  // width: 75%;
  justify-self: center;
}

#button-text {
  color: white;
  text-decoration: none;
  padding: 20px 20px;
  font-size: 1.1rem;
}

#logo {
  width: 100%;
  grid-row: 1;
  grid-column: 1/3;
  margin-bottom: 15px;
}

@media only screen and (min-width: 960px) {
  #header-section {
    display: flex;
    justify-content: space-between;
    width: 95%;
    text-align: center;
    position: fixed;
    top: 0;
  }

  #header-button {
    // width: 20%;
  }

  #logo {
    width: 50%;
  }
}