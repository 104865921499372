@import '../../common/colors.scss';

#about-section {
  display: flex;
  flex-direction: column;
  margin: 5px 2% 0;
  gap: 4%;
  align-items: center;
  width: 96%;
  padding-bottom: 55px;
  border-bottom: 10px solid $yellow;
}

#about-paragraphs {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: $blue;
  margin: 25px 0;
}

#about-image {
  width: 80%;
}

.about-paragraph {
  font-size: 1.1rem;
  width: 95%;
}

.about-line {
  border: 0.5px solid $blue;
  opacity: 100%;
}

@media only screen and (min-width: 960px) {
	#about-section {
		display: flex;
		flex-direction: row;
		margin: 12% 2% 0;
    justify-content: center;
	
	}

  #about-paragraphs {
    justify-content: center;
  }

  #about-image {
    width: 35%;
  }
}
