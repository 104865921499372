.App {
  text-align: center;
  margin: 0 auto;
  /* background-image: url(./images/backgrounds/Background\ 6.png); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 95%;
  /* opacity: .6; */
}

p {
  font-family: "utopia-std";
}

p {
  font-size: 1.2rem;
}

button {
  font-size: 1.3rem;
}